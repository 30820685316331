import { createAction } from "@reduxjs/toolkit";

const actions = {
  fetchOrchidsRequest: createAction("orchids/fetchOrchidsRequest"),
  fetchOrchidsSuccess: createAction("orchids/fetchOrchidsSuccess"),
  fetchOrchidsError: createAction("orchids/fetchOrchidsError"),

  fetchSearchOrchidsRequest: createAction("orchids/fetchSearchOrchidsRequest"),
  fetchSearchOrchidsSuccess: createAction("orchids/fetchSearchOrchidsSuccess"),
  fetchSearchOrchidsError: createAction("orchids/fetchSearchOrchidsError"),

  fetchOrchidCategoriesRequest: createAction(
    "orchids/fetchOrchidCategoriesRequest"
  ),
  fetchOrchidCategoriesSuccess: createAction(
    "orchids/fetchOrchidCategoriesSuccess"
  ),
  fetchOrchidCategoriesError: createAction(
    "orchids/fetchOrchidCategoriesError"
  ),

  fetchOrchidByIdRequest: createAction("orchids/fetchOrchidByIdRequest"),
  fetchOrchidByIdSuccess: createAction("orchids/fetchOrchidByIdSuccess"),
  fetchOrchidByIdError: createAction("orchids/fetchOrchidByIdError"),

  changeFilter: createAction("orchids/filter"),
};

export default actions;
