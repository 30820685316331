import React from "react";
import MainPage from "./pages/MainPage/MainPage";

const App = () => (
  <>
    <MainPage />
  </>
);

export default App;
