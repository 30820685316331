import LogoTitle from "./LogoTitle";
import styles from "./Header.module.scss";

export default function Header() {
  return (
    <header className={styles.header}>
      <LogoTitle />
    </header>
  );
}
