import { useDispatch } from "react-redux";
import Logo from "../../../images/orchid.svg";
// import NewYearLogo from "../../../images/new-year-orchid.svg";
import { actions } from "../../../redux/orchids";
import styles from "./LogoTitle.module.scss";

export default function LogoTitle() {
  const dispatch = useDispatch();
  const onChangeFilter = (value) => dispatch(actions.changeFilter(value));

  const resetParams = () => {
    onChangeFilter("");
  };

  return (
    <div className={styles.wrapper} onClick={resetParams}>
      <img src={Logo} className={styles.logo} alt="Orchid Logo" />
      <p className={styles.name}>Plantatica</p>
    </div>
  );
}
