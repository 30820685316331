import axios from "axios";
import { actions } from "./";

axios.defaults.baseURL = "https://plantatica-backend.herokuapp.com";

const fetchOrchids = () => async (dispatch) => {
  dispatch(actions.fetchOrchidsRequest());

  axios
    .get("/orchids")
    .then(({ data }) => dispatch(actions.fetchOrchidsSuccess(data)))
    .catch((error) => dispatch(actions.fetchOrchidsError(error)));
};

const searchOrchids = (searchQuery) => async (dispatch) => {
  dispatch(actions.fetchSearchOrchidsRequest());

  axios
    .get(`/search?searchString=${searchQuery}`)
    .then(({ data }) => dispatch(actions.fetchSearchOrchidsSuccess(data)))
    .catch((error) => dispatch(actions.fetchSearchOrchidsError(error)));
};

const fetchOrchidCategories = () => async (dispatch) => {
  dispatch(actions.fetchOrchidCategoriesRequest());

  axios
    .get("/categories")
    .then(({ data }) => dispatch(actions.fetchOrchidCategoriesSuccess(data)))
    .catch((error) => dispatch(actions.fetchOrchidCategoriesError(error)));
};

const fetchOrchidById = (orchidId) => async (dispatch) => {
  dispatch(actions.fetchOrchidByIdRequest());

  axios
    .get(`/orchid?id=${orchidId}`)
    .then(({ data }) => dispatch(actions.fetchOrchidByIdSuccess(data)))
    .catch((error) => dispatch(actions.fetchOrchidByIdError(error)));
};

const operations = {
  fetchOrchids,
  searchOrchids,
  fetchOrchidCategories,
  fetchOrchidById,
};

export default operations;
