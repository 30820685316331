import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors, operations } from "../../redux/orchids/";
import styles from "./SearchForm.module.scss";

export default function SearchForm() {
  const dispatch = useDispatch();
  const onChangeFilter = (value) => dispatch(actions.changeFilter(value));
  const onSearch = (value) => dispatch(operations.searchOrchids(value));

  const filter = useSelector(selectors.getFilter);

  const debounceSearch = (event) => {
    onChangeFilter(event.target.value);

    onSearch(event.target.value);
  };

  return (
    <>
      <label className={styles.label}>
        <input
          className={styles.input}
          type="text"
          value={filter}
          onChange={debounceSearch}
          placeholder="Orchids.."
        />
      </label>
    </>
  );
}
