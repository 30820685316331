import Spinner from "react-loader-spinner";
import styles from "./Loader.module.scss";

export default function Loader() {
  return (
    <Spinner
      visible={true}
      className={styles.LoaderMain}
      type="Watch"
      color="#e27fd5"
      height={60}
      width={60}
    />
  );
}
