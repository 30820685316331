import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { actions } from "./";

const items = createReducer([], {
  [actions.fetchSearchOrchidsSuccess]: (_, { payload }) => payload,
});

const categories = createReducer([], {
  [actions.fetchOrchidCategoriesSuccess]: (_, { payload }) => payload,
});

const item = createReducer(
  {},
  {
    [actions.fetchOrchidByIdSuccess]: (state, { payload }) => [
      ...state,
      payload,
    ],
  }
);

const filter = createReducer("", {
  [actions.changeFilter]: (_, { payload }) => payload,
});

const loading = createReducer(false, {
  // [actions.fetchOrchidsRequest]: () => true,
  // [actions.fetchOrchidsSuccess]: () => false,
  // [actions.fetchOrchidsError]: () => false,
  [actions.fetchSearchOrchidsRequest]: () => true,
  [actions.fetchSearchOrchidsSuccess]: () => false,
  [actions.fetchSearchOrchidsError]: () => false,
  [actions.fetchOrchidCategoriesRequest]: () => true,
  [actions.fetchOrchidCategoriesSuccess]: () => false,
  [actions.fetchOrchidCategoriesError]: () => false,
});

export default combineReducers({ items, categories, item, filter, loading });
