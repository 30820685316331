import Header from "../../components/Header";
import OrchidList from "../../components/OrchidList";
import SearchForm from "../../components/SearchForm";
import GoBackToTop from "../../components/GoBackToTop";
import styles from "./MainPage.module.scss";

export default function MainPage() {
  return (
    <div className={styles.mainPage}>
      <Header />
      <SearchForm />
      <OrchidList />
      <GoBackToTop />
    </div>
  );
}
