import classnames from "classnames";
import { useEffect, useState } from "react";

import styles from "./GoBackToTop.module.scss";

export default function GoBackToTop() {
  const [showBtn, setShowBtn] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  let classNames = classnames(styles.btn, {
    [styles.show]: showBtn,
    [styles.fadeIn]: fadeIn,
    [styles.fadeOut]: fadeOut,
  });

  useEffect(() => {
    window.addEventListener("scroll", trackScroll);
    return () => window.removeEventListener("scroll", trackScroll);
  }, []);

  const trackScroll = () => {
    let scrolled = window.pageYOffset;
    let coords = document.documentElement.clientHeight;

    if (scrolled > coords) {
      setShowBtn(true);
      setFadeIn(true);
      setFadeOut(false);
    }
    if (scrolled < coords) {
      setShowBtn(false);
      setFadeOut(true);
      setFadeIn(false);
    }
  };

  const backToTop = () => {
    if (window.pageYOffset > 0) {
      window.scrollBy(0, -80);
      setTimeout(backToTop, 15);
    }
  };

  return <div className={classNames} title="To top" onClick={backToTop}></div>;
}
