import { useMemo, useState } from "react";

export default function useSortableData(items, config = null) {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const parsedA = parseFloat(a[sortConfig.key]);
        const parsedB = parseFloat(b[sortConfig.key]);

        if (!isNaN(parsedA) && !isNaN(parsedB)) {
          return sortConfig.direction === "ascending"
            ? parsedA - parsedB
            : parsedB - parsedA;
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const requestSortOrchs = (key, direction) => {
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, requestSortOrchs, sortConfig };
}
