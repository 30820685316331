import { createSelector } from "@reduxjs/toolkit";

const getOrchids = (state) => state.orchids.items;
const getOrchidCategories = (state) => state.orchids.categories;
const getFilter = (state) => state.orchids.filter;
const getLoading = (state) => state.orchids.loading;

const getVisibleOrchids = createSelector(
  [getOrchids, getFilter],
  (orchids, filter) => {
    const normalizedFilter = filter.toLowerCase();

    return orchids.filter(({ title = "default value" }) =>
      title.toLowerCase().includes(normalizedFilter)
    );
  }
);

const selectors = {
  getOrchids,
  getFilter,
  getOrchidCategories,
  getLoading,
  getVisibleOrchids,
};

export default selectors;
