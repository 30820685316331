import { useSelector } from "react-redux";
import { selectors } from "../../redux/orchids";
import styles from "./OrchidTable.module.scss";
import useSortableData from "../../helpers/useSortableData";

export default function OrchidTable() {
  const visibleOrchids = useSelector(selectors.getVisibleOrchids);
  const { items, requestSort, sortConfig } = useSortableData(visibleOrchids);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    if (sortConfig.key === name) {
      if (sortConfig.direction === "ascending") return styles.ascending;
      else return styles.descending;
    } else return undefined;
  };

  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr className={styles.trHead}>
          <th scope="col" className={styles.nameHead}>
            {items.length > 1 ? (
              <button
                type="button"
                onClick={() => requestSort("title")}
                className={`${styles.buttonHead} ${getClassNamesFor("title")}`}
              >
                Name
              </button>
            ) : (
              "Name"
            )}
          </th>
          <th scope="col" className={styles.countryHead}>
            {items.length > 1 ? (
              <button
                type="button"
                onClick={() => requestSort("country")}
                className={`${styles.buttonHead} ${getClassNamesFor(
                  "country"
                )}`}
              >
                Country
              </button>
            ) : (
              "Country"
            )}
          </th>
          <th scope="col" className={styles.vendorHead}>
            {items.length > 1 ? (
              <button
                type="button"
                onClick={() => requestSort("vendor")}
                className={`${styles.buttonHead} ${getClassNamesFor("vendor")}`}
              >
                Vendor
              </button>
            ) : (
              "Vendor"
            )}
          </th>
          <th scope="col" className={styles.priceHead}>
            {items.length > 1 ? (
              <button
                type="button"
                onClick={() => requestSort("price")}
                className={`${styles.buttonHead} ${getClassNamesFor("price")}`}
              >
                Price
              </button>
            ) : (
              "Price"
            )}
          </th>
        </tr>
      </thead>

      <tbody>
        {items.map(({ id, country, price, title, url, vendor }) => (
          <tr key={id}>
            <td data-label="Name" style={{ fontWeight: "bold" }}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {title}
              </a>
            </td>
            <td data-label="Country">{country}</td>
            <td data-label="Vendor">{vendor}</td>
            <td data-label="Price" style={{ fontWeight: "bold" }}>
              {parseFloat(price).toFixed(2)} {price.split(" ")[1]}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
