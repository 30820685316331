import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, operations, selectors } from "../../redux/orchids";
import shortid from "shortid";
import orchidImg from "../../images/orchid-houseplant.jpg";
import styles from "./OrchidList.module.scss";
import Loader from "../Loader";
import OrchidTable from "../OrchidTable/OrchidTable";
import useSortableData from "../../helpers/useSortableData";

export default function OrchidList() {
  const dispatch = useDispatch();

  const categories = useSelector(selectors.getOrchidCategories);
  const { items, requestSortOrchs } = useSortableData(categories);

  useEffect(() => {
    dispatch(operations.fetchOrchidCategories());
    requestSortOrchs("name", "ascending");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const visibleOrchids = useSelector(selectors.getVisibleOrchids);
  const filter = useSelector(selectors.getFilter);
  const loading = useSelector(selectors.getLoading);

  const searchOrchids = (name) => {
    dispatch(actions.changeFilter(name));
    dispatch(operations.searchOrchids(name));
  };

  return (
    <>
      <ol className={styles.list}>
        {filter === "" &&
          !loading &&
          items.map(({ image, name, useLightText }) => (
            <li key={shortid.generate()} className={styles.item}>
              <button
                onClick={() => searchOrchids(name)}
                className={styles.button}
              >
                <div className={styles.nameWrapper}>
                  <p
                    className={
                      useLightText ? styles.nameLight : styles.nameDark
                    }
                  >
                    {name}
                  </p>
                </div>

                <img
                  src={image ? image : orchidImg}
                  alt={name}
                  className={styles.image}
                ></img>
              </button>
            </li>
          ))}
      </ol>
      {visibleOrchids.length !== 0 && filter && !loading && <OrchidTable />}

      {visibleOrchids.length === 0 && filter && !loading && (
        <p className={styles.notFoundMsg}>
          Sorry, there are no such orchids :(
        </p>
      )}
      {loading && <Loader />}
    </>
  );
}
